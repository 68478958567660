import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1047.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1047.000000) scale(0.100000,-0.100000)">




<path d="M2597 6084 c-9 -9 -10 -1476 -1 -1498 9 -24 43 -20 49 7 3 12 4 353
3 757 -3 731 -3 735 -23 738 -12 2 -24 0 -28 -4z"/>
<path d="M1564 6051 c-46 -28 -64 -59 -64 -112 0 -129 163 -176 230 -66 41 67
14 154 -56 183 -45 19 -73 18 -110 -5z"/>
<path d="M1880 5967 c-52 -26 -61 -89 -21 -138 15 -19 28 -23 61 -23 51 2 78
26 87 77 5 31 1 40 -25 67 -33 32 -62 37 -102 17z"/>
<path d="M1193 5880 c-41 -24 -53 -48 -53 -101 0 -76 52 -129 126 -129 75 0
123 49 124 126 0 54 -21 89 -67 113 -33 17 -94 13 -130 -9z"/>
<path d="M2079 5795 c-69 -37 -83 -137 -28 -199 35 -41 81 -53 132 -37 70 24
103 97 78 174 -22 66 -116 98 -182 62z"/>
<path d="M2891 5782 c-10 -20 -14 -862 -4 -888 8 -20 526 -20 598 0 80 22 118
42 156 83 47 50 64 95 63 169 -1 70 -33 138 -84 176 -38 27 -38 47 0 83 66 63
77 192 22 270 -73 105 -152 125 -494 125 -230 0 -248 -1 -257 -18z m530 -211
c28 -34 24 -75 -8 -101 -24 -19 -37 -20 -152 -18 -123 3 -125 3 -131 27 -3 14
-3 45 0 70 6 56 14 58 167 51 95 -5 107 -7 124 -29z m-15 -321 c15 -6 33 -18
40 -28 20 -26 17 -86 -5 -113 -19 -23 -24 -24 -163 -24 l-143 0 -3 79 c-2 57
1 82 10 87 19 12 232 11 264 -1z"/>
<path d="M8308 5786 c-53 -19 -71 -41 -75 -94 -5 -59 12 -92 57 -114 112 -56
230 30 189 138 -25 66 -99 97 -171 70z"/>
<path d="M8887 5790 c-113 -29 -180 -104 -194 -217 -7 -60 -11 -65 -68 -73
l-40 -5 0 -85 0 -85 40 -5 c22 -3 46 -9 53 -13 9 -6 12 -61 12 -221 0 -151 3
-215 12 -224 8 -8 47 -12 108 -12 61 0 100 4 108 12 9 9 12 72 12 223 0 151 3
214 12 223 7 7 40 12 80 12 l67 0 6 33 c4 17 5 57 3 87 l-3 55 -70 3 c-38 2
-75 8 -82 14 -21 17 -15 57 12 84 24 24 31 25 83 19 l58 -7 27 50 c39 71 36
89 -16 112 -52 23 -166 33 -220 20z"/>
<path d="M1023 5630 c-26 -11 -43 -40 -43 -75 0 -39 18 -65 56 -81 95 -40 166
91 82 150 -25 17 -61 20 -95 6z"/>
<path d="M1457 5563 c-4 -3 -7 -111 -7 -238 0 -192 3 -235 15 -245 10 -8 57
-11 167 -8 172 4 215 16 249 72 31 51 19 127 -27 174 -18 18 -18 25 6 52 28
33 27 108 -2 145 -37 47 -72 55 -241 55 -85 0 -157 -3 -160 -7z m281 -109 c49
-34 8 -74 -78 -74 -60 0 -80 11 -80 45 0 12 5 26 12 33 18 18 119 15 146 -4z
m12 -194 c46 -46 7 -80 -91 -80 -66 0 -68 0 -74 29 -3 17 -1 39 5 50 16 30
130 31 160 1z"/>
<path d="M4120 5514 c-198 -34 -300 -147 -300 -331 0 -123 36 -207 115 -267
68 -52 128 -68 255 -69 97 -1 124 3 173 22 53 21 117 64 117 79 0 4 -23 31
-51 60 -41 43 -55 52 -72 47 -128 -41 -172 -44 -234 -16 -37 17 -64 54 -49 68
3 3 94 6 203 6 109 0 208 4 221 8 20 8 22 15 22 72 0 186 -115 305 -310 320
-36 3 -76 3 -90 1z m115 -188 c42 -18 64 -58 45 -81 -9 -11 -34 -15 -105 -15
-101 0 -126 11 -106 48 19 36 47 50 124 61 4 0 23 -6 42 -13z"/>
<path d="M5633 5510 c-162 -29 -266 -146 -279 -313 -17 -222 153 -371 406
-354 47 3 105 13 130 22 60 21 120 62 120 81 0 8 -22 38 -48 66 l-49 51 -49
-19 c-63 -24 -167 -25 -211 -1 -31 16 -52 52 -38 65 4 4 99 7 212 7 191 0 206
1 219 19 29 41 10 167 -36 240 -68 107 -223 163 -377 136z m150 -186 c22 -7
49 -58 41 -78 -5 -13 -24 -16 -99 -16 -51 0 -100 3 -109 6 -23 9 -20 37 8 63
39 37 88 45 159 25z"/>
<path d="M6520 5511 c-14 -5 -44 -18 -67 -29 -53 -27 -70 -28 -78 -2 -6 19
-13 21 -103 18 l-97 -3 -3 -310 c-2 -283 -1 -311 15 -322 21 -16 176 -17 204
-3 18 10 19 23 19 191 0 201 9 241 57 260 34 13 78 5 101 -18 15 -14 17 -45
20 -217 2 -122 7 -205 14 -213 13 -15 185 -18 208 -3 12 7 16 45 20 202 3 130
9 200 18 215 28 49 94 58 133 17 23 -25 24 -30 27 -222 2 -119 7 -201 14 -209
7 -9 41 -13 109 -13 99 0 100 0 110 28 8 18 9 97 5 245 -5 186 -9 223 -25 255
-40 77 -93 116 -184 133 -81 14 -150 -1 -244 -57 -32 -19 -32 -19 -60 4 -57
45 -157 70 -213 53z"/>
<path d="M7790 5513 c-52 -7 -104 -25 -131 -45 -29 -22 -59 -19 -59 6 0 21
-32 27 -125 24 l-80 -3 -3 -444 c-1 -319 1 -448 9 -457 13 -16 185 -20 208 -5
12 8 15 40 17 148 1 76 2 141 3 146 2 12 23 8 70 -15 36 -18 62 -22 123 -22
126 2 205 50 261 159 29 56 31 67 31 170 1 101 -1 115 -27 167 -15 31 -44 73
-65 92 -52 52 -164 90 -232 79z m48 -220 c56 -42 65 -154 16 -212 -35 -41
-117 -56 -166 -30 -53 28 -77 149 -41 210 35 59 133 76 191 32z"/>
<path d="M2221 5474 c-67 -85 48 -196 124 -119 36 35 35 96 -1 124 -39 31 -97
28 -123 -5z"/>
<path d="M4605 5490 c-7 -11 14 -53 71 -143 24 -37 55 -90 69 -117 l25 -50
-20 -38 c-11 -20 -54 -89 -95 -152 -56 -86 -71 -117 -63 -127 14 -18 208 -18
231 0 9 6 34 41 55 77 50 84 67 84 109 5 48 -91 56 -95 184 -95 59 0 110 4
113 9 9 14 -8 47 -84 161 -97 144 -102 157 -83 195 18 35 85 148 93 155 16 16
60 112 55 120 -7 11 -214 14 -231 2 -6 -4 -28 -37 -48 -74 -21 -38 -42 -68
-47 -68 -6 0 -18 14 -27 31 -9 17 -29 51 -44 75 l-28 44 -114 0 c-64 0 -118
-4 -121 -10z"/>
<path d="M8247 5493 c-4 -3 -7 -147 -7 -319 0 -275 2 -313 16 -318 33 -13 192
-6 207 8 12 12 14 68 15 313 0 191 -4 303 -10 311 -11 13 -209 18 -221 5z"/>
<path d="M9134 5485 c-5 -12 67 -210 117 -320 33 -75 109 -282 109 -299 0 -12
-23 -74 -51 -138 -28 -64 -49 -124 -46 -133 10 -24 208 -22 229 3 14 15 138
284 208 452 16 36 62 143 104 239 42 96 76 182 76 193 0 16 -10 18 -114 18
-79 0 -117 -4 -124 -13 -13 -16 -47 -98 -87 -215 -34 -98 -49 -123 -64 -106
-5 5 -30 72 -56 149 -66 198 -53 185 -186 185 -89 0 -111 -3 -115 -15z"/>
<path d="M969 5341 c-33 -33 -39 -45 -39 -82 0 -47 26 -101 57 -117 18 -10 58
-15 100 -13 12 1 38 16 57 35 31 29 36 39 36 81 0 83 -50 135 -130 135 -36 0
-48 -6 -81 -39z"/>
<path d="M2224 5266 c-69 -30 -99 -101 -71 -167 21 -50 48 -69 105 -76 60 -7
91 9 122 60 38 61 14 144 -51 179 -40 21 -63 22 -105 4z"/>
<path d="M1079 5037 c-44 -34 -49 -83 -13 -126 52 -62 154 -26 154 54 0 65
-90 111 -141 72z"/>
<path d="M2085 4934 c-55 -60 -19 -144 61 -144 53 0 88 35 88 87 0 75 -98 112
-149 57z"/>
<path d="M1330 4899 c-39 -8 -85 -56 -93 -97 -8 -37 11 -97 38 -122 53 -50
152 -40 192 19 67 100 -18 225 -137 200z"/>
<path d="M1863 4873 c-12 -2 -36 -20 -54 -40 -29 -33 -31 -41 -27 -92 4 -47
10 -59 38 -82 41 -35 80 -44 123 -29 84 27 117 132 64 201 -29 38 -84 54 -144
42z"/>
<path d="M1548 4740 c-26 -26 -30 -36 -25 -67 14 -83 125 -105 162 -32 21 40
19 63 -11 98 -36 43 -84 43 -126 1z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
